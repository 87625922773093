import React from "react";
import "./CourseComponent.scss"

const ContentComponent = ({ htmlContent }) => {
  // Define styles for the specific sections
  const styles = {
    content: {
      color: "blue",
      filter: "blur(1px)",
    },
    notes: {
      backgroundColor: "red",
      color: "white",
      padding: "20px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    summary: {
      backgroundColor: "#F5F5F7",
      color: "#3D3C6E",
      padding: "30px",
      borderRadius: "8px",
      fontSize: "18px",
      fontWeight: 500,
      marginTop: "50px",
    },
    media: {
      width: "100%",
      backgroundColor: "#7A7C7F", // Grey background color
      display: "block", // Ensures elements are block-level to span full width
      margin: "0 auto", // Center content if needed
    }
  };

  // Function to decode HTML entities (convert &lt; to <, &gt; to >)
  const decodeHtmlEntities = (html) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = html;
    return textArea.value;
  };

  // Function to handle iframe, image, and video injection
  const handleMediaInjection = (html) => {
    const decodedHtml = decodeHtmlEntities(html);

    let resultHtml = decodedHtml;
    let match;

    // Handle iframe replacement
    const iframeRegex = /<iframe.?src="([^"]+)".?<\/iframe>/g;
    // Handle iframe replacement
    resultHtml = resultHtml.replace(iframeRegex, (match, src) => {
      return `<div class="iframe-container">
                <iframe 
                  src="${src}" 
                  width="100%" 
                  height="450px" 
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                  sandbox="allow-scripts allow-same-origin"
                  style="width: 100%; background-color: #7A7C7F;"
                ></iframe>
              </div>`;
    });

    // Handle image replacement
    const imageRegex = /<img.?src="([^"]+)".?\/?>/g;
    resultHtml = resultHtml.replace(imageRegex, (match, src) => {
      return `<div class="image-container">
                <img 
                  src="${src}" 
                  alt="Content Image" 
                  style="width: 100%; height: auto; background-color: #7A7C7F;" 
                />
              </div>`;
    });

    // Handle video replacement
    const videoRegex = /<video.?src="([^"]+)".?<\/video>/g;
    resultHtml = resultHtml.replace(videoRegex, (match, src) => {
      return `<div class="video-container">
                <video width="100%" height="auto" controls 
                       style="background-color: #7A7C7F;">
                  <source src="${src}" />
                  Your browser does not support the video tag.
                </video>
              </div>`;
    });

    return resultHtml;
  };

  // Replace special tags like #video, #content, #notes, and #summary with custom HTML
  const replaceTagsWithStyles = (html) => {
    return html
      ?.replace(/#video\s*<a href="([^"]+)">/g, "")
      ?.replace(/#content/g, `<span style="color: #333333">`)
      ?.replace(
        /#notes/g,
        `<div style="background-color: #ED1C240D; color: #333333; padding: 20px; border-radius: 12px; display: flex; align-items: center; gap: 8px;"><span style="font-size: 18px; font-weight: bolder;">`
      )
      ?.replace(/<\/p>/g, "</div></p>")
      ?.replace(
        /#summary/g,
        `<div style="background-color: ${styles.summary.backgroundColor}; color: ${styles.summary.color}; padding: ${styles.summary.padding}; border-radius: ${styles.summary.borderRadius}; font-size: ${styles.summary.fontSize}; font-weight: ${styles.summary.fontWeight}; margin-top:${styles.summary.marginTop}">`
      );
  };

  // Apply tag replacements first
  let styledHtmlContent = replaceTagsWithStyles(htmlContent);

  // Now handle media injections (iframe, image, video)
  const processedContent = handleMediaInjection(styledHtmlContent);

  return <div dangerouslySetInnerHTML={{ __html: processedContent }} />;
};

export default ContentComponent;
