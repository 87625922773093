/** @format */

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import ChangePassword from "./pages/Sidebar/Settings/ChangePassword/ChangePassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import AdminList from "./pages/AdminList";
import AddCustomer from "./pages/Customer/AddCustomer";
import ViewCustomer from "./pages/Customer/ViewCustomer";
import AdminUser from "./pages/AdminManagement/AdminUser";
import ViewAdmin from "./pages/AdminManagement/ViewAdmin";
import AddAdmin from "./pages/AdminManagement/AddAdmin";
import EditAdmin from "./pages/AdminManagement/EditAdmin";
import LoginLogs from "./pages/Sidebar/LoginActivity/LoginLogs";
import Settings from "./pages/Sidebar/Settings/Settings";
import TermsAndConditions from "./pages/Content/TermsConditions/TermsAndConditions";
import AddTermsAndConditions from "./pages/Content/TermsConditions/AddTermsAndConditions";
import EditTermsAndConditions from "./pages/Content/TermsConditions/EditTermsAndConditions";
import Support from "./pages/Content/Support/Support";
import EditSupport from "./pages/Content/Support/EditSupport";
import AddSupport from "./pages/Content/Support/AddSupport";
import AboutUs from "./pages/Content/AboutUs/AboutUs";
import AddAboutUs from "./pages/Content/AboutUs/AddAboutUs";
import EditAboutUs from "./pages/Content/AboutUs/EditAboutUs";
import Error401 from "./pages/ErrorPages/Error401";
import User from "./pages/Customer/User";
import BannerContent from "./pages/Content/BannerContent/BannerContent";
import AddBanner from "./pages/Content/BannerContent/AddBanner";
import EditBanner from "./pages/Content/BannerContent/EditBanner";
import Corporate from "./pages/Corporate/Corporate";
import ViewCorporate from "./pages/Corporate/ViewCorporate";
import AddCorporate from "./pages/Corporate/AddCorporate";
import Course from "./pages/Course/Course/Course";
import AddCourse from "./pages/Course/Course/AddCourse";
import  EditCourse from "./pages/Course/Course/EditCourse"
import ViewCourse from "./pages/Course/ViewCourse";
import AddLecture from "./pages/Course/AddLecture";
import EditLecture from "./pages/Course/EditLecture";
import AddQuiz from "./pages/Course/AddQuiz";
import Test from "./pages/Test/test";
import AddTest from "./pages/Test/AddTest";
import EditTest from "./pages/Test/EditTest";
import ViewTest from "./pages/Test/ViewTest";
import AddQuestions from "./pages/Test/AddQuestions";
import Forum from "./pages/Content/Forum/Forum";
import EditForum from "./pages/Content/Forum/EditForum";
import ViewQuestions from "./pages/Course/ViewQuestions";
import AddQuizQuestions from "./pages/Course/AddQuizQuestions";
import Payment from "./pages/Payment/Payment";
import CourseLectureEditor from "./pages/Course/Lecture/CourseLectureEditor";
import Ratings from "./pages/Course/Course/Ratings";
import WelcomePage from "./pages/ErrorPages/WelcomePage";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/forgot-password" element={<Forgotpassword />} />
				<Route path="/Login" element={<Login />} />
				<Route path="/error401" element={<Error401 />} />
				<Route path="/viewCourse/:id" element={<ViewCourse />} />
				<Route path="/" element={<MainLayout />}>
					<Route index element={<WelcomePage />} />
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/reset-password" element={<ChangePassword />} />
					<Route path="/addUser" element={<AddCustomer />} />
					<Route path="/viewUser/:id" element={<ViewCustomer />} />
					<Route path="adminUser" element={<AdminList />} />
					<Route path="/adminList" element={<AdminUser />} />
					<Route path="/viewAdmin" element={<ViewAdmin />} />
					<Route path="/addAdmin" element={<AddAdmin />} />
					<Route path="/editAdmin/:id" element={<EditAdmin />} />
					<Route path="/loginLogs" element={<LoginLogs />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/termsAndConditions" element={<TermsAndConditions />} />
					<Route
						path="/addTermsAndConditions"
						element={<AddTermsAndConditions />}
					/>
					<Route
						path="/editTermsAndConditions"
						element={<EditTermsAndConditions />}
					/>
					<Route path="/contact_us" element={<Support />} />
					<Route path="/editSupport/:id" element={<EditSupport />} />
					<Route path="/addSupport" element={<AddSupport />} />
					<Route path="/aboutUs" element={<AboutUs />} />
					<Route path="/addAboutUs" element={<AddAboutUs />} />
					<Route path="/editAboutUs" element={<EditAboutUs />} />
					<Route path="/users" element={<User />} />
					<Route path="/banner" element={<BannerContent />} />
					<Route path="/addBanner" element={<AddBanner />} />
					<Route path="/editBanner/:id" element={<EditBanner />} />
					<Route path="/corporate" element={<Corporate />} />
					<Route path="/viewCorporate/:id" element={<ViewCorporate />} />
					<Route path="/addCorporate" element={<AddCorporate />} />
					<Route path="/course" element={<Course />} />
					<Route path="/editCourse/:id" element={<EditCourse />} />
					
					<Route path="/addCourse" element={<AddCourse />} />
					<Route path="/addLecture/:id" element={<AddLecture />} />
					<Route path="/editLecture/:id" element={<EditLecture />} />
					<Route path="/addQuiz/:id" element={<AddQuiz />} />
					<Route path="/test" element={<Test />} />
					<Route path="/addTest" element={<AddTest />} />
					<Route path="/editTest/:id" element={<EditTest />} />
					<Route path="/viewTest/:id" element={<ViewTest />} />
					<Route path="/addQuestions/:id" element={<AddQuestions />} />
					<Route path="/forum" element={<Forum />} />
					<Route path="/editForum/:id" element={<EditForum />} />
					<Route path="/viewQuestions/:id" element={<ViewQuestions />} />
					<Route path="/addQuizQuestions/:id" element={<AddQuizQuestions />} />
					<Route path="/payment" element={<Payment />} />
					<Route path="/courseLectureEditor/:id" element={<CourseLectureEditor />} />
					<Route path="/courseRatings/:id" element={<Ratings />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
