/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../Course.css";
import { EditCourse, GetCourseById } from "../../../services/Api/CourseApi";
import { BASE_URL_IMAGE } from "../../../services/Host";

const UpdateCourse = () => {
	const { id } = useParams();
	const [courseData, setCourseData] = useState({
		name: "",
		price: "",
		description: "",
		// course_attachments: [],
	});
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [removeImageId, setRemoveImageId] = useState(null);
	const [disable, setDisable] = useState(false);
	const navigate = useNavigate();

	useLayoutEffect(() => {
		GetCourseById(id)
			.then((res) => {
				setCourseData(res.data.data);
				if (
					res.data.data.course_attachments &&
					res.data.data.course_attachments.length > 0
				) {
					const attachment = res.data.data.course_attachments[0];
					setImagePreview(`${BASE_URL_IMAGE}${attachment.file_name}`);
					setRemoveImageId(attachment.id);
				}
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setCourseData((prevData) => ({
			...prevData,
			description: data,
		}));
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setImage(file);
			const previewUrl = URL.createObjectURL(file);
			setImagePreview(previewUrl);
		}
	};

	const handleRemoveImage = () => {
		setImage(null);
		setImagePreview(null);
		setRemoveImageId(null);
		document.getElementById("imageInput").value = "";
	};

	const navigateToCourse = () => {
		navigate("/course");
	};
	console.log(id, "ddd");
	const navigateTolecture = (event, id) => {
		navigate(`/courseLectureEditor/${id}`);
	};

	const onChange = (e) => {
		setCourseData({ ...courseData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setDisable(true);

		const formData = new FormData();
		formData.append("name", courseData.name || "");
		formData.append("price", courseData.price || "");
		formData.append("description", courseData.description || "");

		// if (image) {
		// 	formData.append("images", image);
		// }

		// if (removeImageId) {
		// 	formData.append("removeImageIds", removeImageId);
		// }

		EditCourse(id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Course updated successfully!");
					// navigate(`/courseLectureEditor/${id}`);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			})
			.finally(() => {
				setDisable(false);
			});
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">COURSE MANAGEMENT</h3>
					<p className="page-sub-title">Update Course Details</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToCourse}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Course</span>
					</Button>
				</div>
			</Box>
			<Form onSubmit={handleSubmit}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					{/* <Card style={{ width: "30%", marginRight: "20px" }}>
						<Form.Group className="mb-3">
							<Form.Label>Image:</Form.Label>
							<Form.Control
								type="file"
								accept="image/*"
								id="imageInput"
								onChange={handleImageChange}
							/>
						</Form.Group>

						{imagePreview && (
							<div
								style={{
									border: "1px solid #ccc",
									borderRadius: "5px",
									padding: "10px",
									marginBottom: "10px",
								}}
							>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										crossOrigin="anonymous"
										src={imagePreview}
										alt="Preview"
										style={{
											height: "80px",
											width: "80px",
											marginRight: "10px",
										}}
									/>
									<DeleteIcon
										fontSize="large"
										color="warning"
										style={{ marginLeft: "auto", cursor: "pointer" }}
										onClick={handleRemoveImage}
									/>
								</div>
							</div>
						)}
					</Card> */}
					<Card style={{ width: "100%" }}>
						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Course Name:</Form.Label>
									<Form.Control
										name="name"
										defaultValue={courseData.name}
										type="text"
										onChange={onChange}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Price:</Form.Label>
									<Form.Control
										name="price"
										defaultValue={courseData.price}
										type="number" // Use type="number" to enable min and max attributes
										min="0" // Set minimum value to 0
										max="999" // Set maximum value to 999
										onChange={(e) => {
											let value = parseInt(e.target.value, 10);
											if (isNaN(value)) value = 0; // If not a number, set to 0
											if (value > 999) value = 999; // Limit to 999 if above
											if (value < 0) value = 0; // Limit to 0 if below
											e.target.value = value; // Update the input value
											onChange(e); // Call the original onChange handler
										}}
									/>
									<Form.Text muted>
										You can enter a price between 0 and 999.
									</Form.Text>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Group className="mb-3">
								<Form.Label>Course Description:</Form.Label>
								<CKEditor
									data={courseData.description}
									editor={ClassicEditor}
									onChange={handleEditorChange}
									config={{
										height: "50%",
									}}
								/>
							</Form.Group>
						</Row>
					</Card>
				</div>

				<div style={{ display: "flex", flexDirection: "row-reverse" }}>
					<Button
						label="Next"
						icon="pi pi-arrow-right"
						iconPos="right"
						severity="secondary"
						onClick={(event) => navigateTolecture(event, id)}
						style={{
							height: "45px",
							marginLeft: "10px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
					/>

					<Button
						label="Cancel"
						icon="pi pi-times"
						severity="secondary"
						onClick={navigateToCourse}
						style={{
							height: "45px",
							marginLeft: "10px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
					/>

					<Button
						label={disable ? "Updating...." : "Update"}
						icon="pi pi-check"
						severity="info"
						onClick={handleSubmit}
						disabled={disable}
						style={{
							height: "45px",
							marginLeft: "10px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
					/>
				</div>
			</Form>
		</Box>
	);
};

export default UpdateCourse;
