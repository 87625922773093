import React, { useEffect, useMemo, useState } from "react";
import { Progress, List, Drawer, Button, Col, Row } from "antd";
import { CheckCircleOutlined, MenuOutlined } from "@ant-design/icons";
import "./ViewCourse.scss";
import { Container, Form } from "react-bootstrap";
import ContentComponent from "./ContentComponent";
import { useParams } from "react-router";
import {
  GetCourseById,
  GetFindLecturesById,
  GetQuestionsByLectureId,
} from "../../services/Api/CourseApi";

const ViewCourse = () => {
  const [lecturesList, setLecturesList] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [lectureContent, setLectureContent] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const { id } = useParams();

  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  useEffect(() => {
    GetCourseById(id, headers)
      .then((res) => {
        setCourseTitle(res?.data?.data?.name || "");
        setLecturesList(res?.data?.data?.course_lectures || []);
        if (res?.data?.data?.course_lectures.length > 0) {
          setSelectedLecture(res?.data?.data?.course_lectures[0]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [id, headers]);

  useEffect(() => {
    if (selectedLecture) {
      GetFindLecturesById(selectedLecture.id)
        .then((res) => {
          setLectureContent(res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
        });

      GetQuestionsByLectureId(selectedLecture.id)
        .then((res) => {
          setQuestions(res.data.data.lecture_quiz);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [selectedLecture]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const handleLectureClick = (lecture) => {
    setSelectedLecture(lecture);
    setDrawerVisible(false);
  };

  const handleChange = (e, questionIndex, answerId) => {
    const { name, value } = e.target;
    const selectedAnswerData = questions[questionIndex].quiz_answers.find(
      (answer) => answer.id === parseInt(value)
    );
    const isCorrect = selectedAnswerData.correct;

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: value,
    }));

    setSelectedAnswer((prevSelectedAnswer) => ({
      ...prevSelectedAnswer,
      [questionIndex]: {
        id: answerId,
        correct: isCorrect,
      },
    }));

    if (!isCorrect) {
      questions[questionIndex].quiz_answers.forEach((answer) => {
        if (answer.correct) {
          document
            .querySelector(`#q${questionIndex + 1}-${answer.id}`)
            .closest(".custom-radio")
            .classList.add("showCorrectAnswer");
        }
      });
    }
  };

  const currentLevel =
    lecturesList.findIndex((lecture) => lecture.id === selectedLecture?.id) + 1;
  const totalLevels = lecturesList.length;

  return (
    <Container>
      <div className="course-ongoing">
        <Button
          className="menu-button"
          icon={<MenuOutlined />}
          onClick={showDrawer}
        />
        <Drawer
          title={courseTitle}
          placement="left"
          closable={true}
          onClose={onClose}
          open={drawerVisible}
        >
          <List
            dataSource={lecturesList}
            renderItem={(item, index) => (
              <List.Item
                className={`lecture-item ${
                  item.completed ? "completed" : ""
                } ${item.id === selectedLecture?.id ? "active" : ""}`}
                onClick={() => handleLectureClick(item)}
              >
                {index + 1}. {item.name}
                {item.completed && (
                  <span className="completed-badge">Completed</span>
                )}
              </List.Item>
            )}
          />
        </Drawer>
        <div className="sidebar">
          <h2>{courseTitle}</h2>

          <List
            dataSource={lecturesList}
            renderItem={(item, index) => (
              <List.Item
                className={`lecture-item ${
                  item.is_completed ? "completed" : ""
                } ${item.id === selectedLecture?.id ? "active" : ""}`}
                onClick={() => handleLectureClick(item)}
              >
                {index + 1}. {item.name}
                {item.is_completed && (
                  <span className="completed-badge">Completed</span>
                )}
              </List.Item>
            )}
          />
        </div>
        <div className="content">
          <h3>
            Level {currentLevel} of {totalLevels}
          </h3>
          {selectedLecture?.type === "Text" && (
            <>
              <h1>{lectureContent?.name}</h1>

              <ContentComponent htmlContent={lectureContent?.content} />
            </>
          )}
          {selectedLecture?.type === "Quiz" && (
            <div className="test-knowledge">
              <h2>Test Your Knowledge</h2>
              {questions && questions.length > 0 ? (
                <Form className="test-form">
                  {questions.map((q, qIndex) => (
                    <Form.Group as={Row} className="mb-3" key={q.id}>
                      <Form.Label as="legend" column sm={12}>
                        {qIndex + 1}. {q.question}
                      </Form.Label>
                      <Col sm={24}>
                        {q.quiz_answers && q.quiz_answers.length > 0 ? (
                          <ul
                            style={{ listStyleType: "none", paddingLeft: 0 }}
                          >
                            {q.quiz_answers.map((answer, aIndex) => (
                              <li
                                key={answer.id}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "8px",
                                }}
                              >
                                {aIndex + 1}. {answer.option}
                                {answer.id === q.right_answer_id && (
                                  <CheckCircleOutlined
                                    style={{
                                      color: "green",
                                      marginLeft: "10px",
                                      fontSize: "16px",
                                    }}
                                  />
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p style={{ color: "red" }}>
                            No options added yet
                          </p>
                        )}
                      </Col>
                    </Form.Group>
                  ))}
                </Form>
              ) : (
                <p style={{ color: "red" }}>No questions added yet</p>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ViewCourse;
