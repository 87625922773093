/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Card } from "primereact/card";
import { GetCourseById } from "../../../services/Api/CourseApi";
import { useNavigate, useParams } from "react-router";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { BASE_URL_IMAGE } from "../../../services/Host";
import { Drawer, Button } from "antd";

const Layout1 = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [courseData, setCourseData] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [courseMenuAnchorEl, setCourseMenuAnchorEl] = useState(null);
	const [drawerVisible, setDrawerVisible] = useState(false);

	const fetchCourseData = async () => {
		try {
			const res = await GetCourseById(id);
			setCourseData(res.data.data);
			if (
				res.data.data.course_attachments &&
				res.data.data.course_attachments.length > 0
			) {
				const attachment = res.data.data.course_attachments[0];
				setImagePreview(`${BASE_URL_IMAGE}${attachment.file_name}`);
			}
		} catch (err) {
			console.log(err, "error");
		}
	};

	useLayoutEffect(() => {
		fetchCourseData();
	}, [id]);

	const truncateDescription = (description, wordLimit) => {
		if (!description) return "";
		const words = description.split(" ");
		if (words.length <= wordLimit) return description;
		return words.slice(0, wordLimit).join(" ") + "...";
	};

	const handleCourseMenuClick = (event) => {
		setCourseMenuAnchorEl(event.currentTarget);
	};

	const handleCourseMenuClose = () => {
		setCourseMenuAnchorEl(null);
	};

	const handleEditCourse = () => {
		navigate(`/editcourse/${id}`);
		handleCourseMenuClose();
	};

	const handleViewCourse = () => {
		navigate(`/viewcourse/${id}`);
		handleCourseMenuClose();
	};

	const handleReadInstructionsClick = () => {
		setDrawerVisible(true);
	};

	const handleDrawerClose = () => {
		setDrawerVisible(false);
	};

	return (
		<div style={{ marginBottom: "20px" }}>
			{/* Blue line with "Read Instructions" text */}
			<div
				onClick={handleReadInstructionsClick}
				style={{
					marginBottom: "10px",
					color: "blue",
					display: "flex",
					flexFlow: "row-reverse",
					textDecoration: "underline",
					cursor: "pointer",
				}}
			>
				Read Instructions
			</div>

			<Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div>
						{imagePreview && (
							<img
								crossOrigin="anonymous"
								src={imagePreview}
								alt="Preview"
								style={{
									height: "80px",
									width: "80px",
									marginRight: "10px",
									borderRadius: "10px",
								}}
							/>
						)}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "95%",
							}}
						>
							<h5>{courseData?.name}</h5>
							<div
								dangerouslySetInnerHTML={{
									__html: truncateDescription(courseData?.description, 40),
								}}
							/>
						</div>
						<div>
							<IconButton
								aria-controls="course-menu"
								aria-haspopup="true"
								onClick={handleCourseMenuClick}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="course-menu"
								anchorEl={courseMenuAnchorEl}
								open={Boolean(courseMenuAnchorEl)}
								onClose={handleCourseMenuClose}
							>
								<MenuItem onClick={handleEditCourse}>Edit Course</MenuItem>
								<MenuItem onClick={handleViewCourse}>View Course</MenuItem>
							</Menu>
						</div>
					</div>
				</div>
			</Card>

			{/* Ant Design Drawer for instructions */}
			<Drawer
				title="Course Instructions"
				placement="right"
				onClose={handleDrawerClose}
				open={drawerVisible}
				width={400}
			>
				<div>
					<p>Follow the instructions below to add content to your course:</p>
					<ul>
						<li>
							<strong>To add an image:</strong> You can upload or insert an
							image by selecting the <strong>Insert Image</strong> option in
							CKEditor. This will allow you to add an image anywhere in your
							content.
						</li>
						<br />

						<li>
							<strong>To add a video:</strong> For videos from Vimeo or YouTube,
							copy and paste their embed URL directly. For example, an embed URL
							looks like this:
							<br />
							<code>
								&lt;iframe width="560" height="315"
								src="https://www.youtube.com/embed/0bxVTq_A9Ss?si=H1gPYILp-_N9T7Vo"
								title="YouTube video player" frameborder="0"
								allow="accelerometer; autoplay; clipboard-write;
								encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerpolicy="strict-origin-when-cross-origin"
								allowfullscreen&gt;&lt;/iframe&gt;
							</code>
						</li>

						<br />

						<li>
							<strong>To add content:</strong> You can add any text or other
							content in the CKEditor. Use the toolbar to format the content
							(bold, italics, etc.) and organize it as needed.
						</li>
						<br />

						<li>
							<strong>To add a summary:</strong> To mark a line or paragraph as
							a summary, type <strong>#summary</strong> in front of that line or
							paragraph.
							<br />
							For example: <code>#summary This is a summary!</code>
							<br />
							<img
								src="https://node.nucleargauges.com:5000/images/images-1731569816089.jpeg"
								alt="Summary"
								crossOrigin="anonymous"
								style={{ height: "100px", marginTop: "10px", width: "100%" }}
							/>
						</li>
						<br />

						<li>
							<strong>To add notes:</strong> To mark a line or paragraph as a
							note, type
							<strong>#notes</strong> in front of that line or paragraph.
							<br />
							For example: <code>#notes This is a note!</code>
							<br />
							<img
								src="https://node.nucleargauges.com:5000/images/images-1731569799701.jpeg"
								alt="Notes"
								crossOrigin="anonymous"
								style={{ height: "100px", marginTop: "10px", width: "100%" }}
							/>
						</li>
					</ul>

					<Button onClick={handleDrawerClose}>Close</Button>
				</div>
			</Drawer>
		</div>
	);
};

export default Layout1;
