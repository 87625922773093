/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";


export const GetUserCount = async (adminToken, role_id) => {
	try {
	  const res = await axios.get(BASE_URL + "admin/dashboard/getUserCount", {
		headers: {
		  "x-access-token": `${adminToken}`,
		  "Content-Type": "multipart/form-data",
		},
	  });
  
	  return res;
	} catch (error) {
	  // Handle error if needed
	  console.error("Error fetching users:", error);
	  throw error;
	}
  };

export const GetCorporateCount = async () => {
	const res = await axios.get(BASE_URL + "/admin/dashboard/getCorporateCount");
	return res;
};

export const GetCourseCount = async () => {
	const res = await axios.get(BASE_URL + "admin/dashboard/getCourseCount");
	return res;
};

export const GetUserPurchasedCount = async () => {
	const res = await axios.get(
		BASE_URL + "admin/dashboard/getUsersWhoPurchasedCourseCount"
	);
	return res;
};

export const GetUsersCompletedCount = async () => {
	const res = await axios.get(
		BASE_URL + "admin/dashboard/getUsersWhoCompletedCourseCount"
	);
	return res;
};

export const GetForumsCount = async () => {
	const res = await axios.get(
		BASE_URL + "admin/dashboard/getForumsSubmittedCount"
	);
	return res;
};

export const GetContactFormsCount = async () => {
	const res = await axios.get(
		BASE_URL + "admin/dashboard/getContactFormsReceivedCount"
	);
	return res;
};

export const GetUserRegisteredStatistics = async (period) => {
	return await axios.post(
		`${BASE_URL}admin/dashboard/getNewUsersRegistered`,
		{ period },
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetCorporateRegisteredStatistics = async (period) => {
	return await axios.post(
		`${BASE_URL}admin/dashboard/getNewCorporatesRegistered`,
		{ period },
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetTopSellingCourse = async () => {
	return await axios.get(
		`${BASE_URL}admin/dashboard/getTopSellingCourses`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetAverageCompletionRate = async () => {
	return await axios.get(
		`${BASE_URL}admin/dashboard/calculateAverageCompletionRate`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetTopRatedCourses = async () => {
	return await axios.get(
		`${BASE_URL}admin/dashboard/getTopRatedCourses`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetAverageCourseRating = async () => {
	return await axios.get(
		`${BASE_URL}admin/dashboard/getAverageCourseRating`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetCoursesSoldStatistics = async (period) => {
	return await axios.post(
		`${BASE_URL}admin/dashboard/coursesSoldStatistics`,
		{ period },
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetTotalRevenueGenerated = async (period) => {
	return await axios.post(
		`${BASE_URL}admin/dashboard/totalRevenueGenerated`,
		{ period },
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

