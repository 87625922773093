/** @format */

import React from "react";
import image from "../../assets/13636-modified-dashboard.gif";

const WelcomePage = () => {
	return (
		<div className="welcome_main_container">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					width: "100%",
                    height:"100%"
				}}
			>
				<div className="left-side">
					<div className="error_heading">
						Welcome To Nuclear Gauges Dashboard 👋🏻
					</div>
					<p className="text-muted my-4">
						Now, you can access the pages you are allowed to. Please explore
						your available features.
					</p>
				</div>
				<div className="right-side">
					<img
						src={image}
						alt="Access"
						style={{ width: "100%", height: "100%" }}
					/>
				</div>
			</div>
		</div>
	);
};

export default WelcomePage;
