/** @format */

import React, { useEffect, useState } from "react";
import { Column, Pie } from "@ant-design/plots";
import { Table, Select,message } from "antd";
import "./Dashboard.css";
import { Spin } from "antd";
import {
	GetContactFormsCount,
	GetCorporateCount,
	GetCourseCount,
	GetForumsCount,
	GetUserCount,
	GetUserPurchasedCount,
	GetUserRegisteredStatistics,
	GetTopSellingCourse,
	GetAverageCompletionRate,
	GetTopRatedCourses,
	GetAverageCourseRating,
	GetTotalRevenueGenerated,
	GetCoursesSoldStatistics,
	GetCorporateRegisteredStatistics,
} from "../../services/Api/DashboardApi";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { BASE_URL_IMAGE } from "../../services/Host";
import StarRatings from "react-star-ratings";
import { useNavigate } from "react-router";
const { Option } = Select;

const Dashboard = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [courseData, setCourseData] = useState([]);
	const [corporateData, setCorporateData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [purchasedCourseCount, setPurchasedCourseCount] = useState([]);
	const [completedCourseCount, setCompletedCourseCount] = useState([]);
	const [contactUsFormData, setContactUsFormData] = useState([]);
	const [topSellingCourseData, setTopSellingCourseData] = useState([]);
	const [averageCompletionRateData, setAverageCompletionRateData] = useState(
		[]
	);
	const [forummData, setForummData] = useState([]);
	const [topRatedCourse, setTopRatedCourse] = useState([]);
	const [averageCouseRating, setAverageCouseRating] = useState([]);
	const [salesCount, setSalesCount] = useState([]);
	const [period, setPeriod] = useState("today");
	const [revenuePeriod, setRevenuePeriod] = useState("weekly");
	const [totalRevenueData, setTotalRevenueData] = useState([]);
	const [courseSoldeData, setCourseSoldData] = useState([]);
	const [courseSoldPeriod, setCourseSoldPeriod] = useState("weekly");
	const [corporateStatisticsData, setcorporateStatisticsData] = useState([]);
	const [corporatePeriod, setCorporatePeriod] = useState("today");

	const getCustomerData = async () => {
		try {
			const result = await GetUserCount(localStorage.getItem("adminToken"));
			setCustomerData(result.data.data);
			console.log("customerdata====>", customerData);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 403) {
				navigate("/error401");
				message.error("Access denied. You do not have the required permissions.");
			} else {
				message.error("Error loading data. Please try again later.");
			}
		}
	};

	const getCorporateData = async () => {
		try {
			const result = await GetCorporateCount(
				localStorage.getItem("adminToken")
			);
			setCorporateData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getCourseData = async () => {
		try {
			const result = await GetCourseCount(localStorage.getItem("adminToken"));
			setCourseData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getpurchasedCourseCount = async () => {
		try {
			const result = await GetUserPurchasedCount(
				localStorage.getItem("adminToken")
			);
			setPurchasedCourseCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getCompletedCourseCount = async () => {
		try {
			const result = await GetUserPurchasedCount(
				localStorage.getItem("adminToken")
			);
			setCompletedCourseCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getContactUsFormCount = async () => {
		try {
			const result = await GetContactFormsCount(
				localStorage.getItem("adminToken")
			);
			setContactUsFormData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getForumCount = async () => {
		try {
			const result = await GetForumsCount(localStorage.getItem("adminToken"));
			setForummData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getTopSellingCourse = async () => {
		try {
			const result = await GetTopSellingCourse(
				localStorage.getItem("adminToken")
			);
			setTopSellingCourseData(result.data.data);
			console.log("sellingcourse====>", result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getAverageCompletionRate = async () => {
		try {
			const result = await GetAverageCompletionRate(
				localStorage.getItem("adminToken")
			);
			console.log("Average Completion Rate Data:", result.data.data); // Log the data
			setAverageCompletionRateData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getTopRatedCourse = async () => {
		try {
			const result = await GetTopRatedCourses(
				localStorage.getItem("adminToken")
			);
			setTopRatedCourse(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getAverageRatingCourse = async () => {
		try {
			const result = await GetAverageCourseRating(
				localStorage.getItem("adminToken")
			);
			setAverageCouseRating(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	// useEffect(() => {
	// 	getCorporateData();
	// 	getCustomerData();
	// 	getCourseData();
	// 	getpurchasedCourseCount();
	// 	getCompletedCourseCount();
	// 	getContactUsFormCount();
	// 	getForumCount();
	// 	getTopSellingCourse();
	// 	getAverageCompletionRate();
	// 	getTopRatedCourse();
	// 	getAverageRatingCourse();
	// }, []);

	  // Function to fetch multiple data at once
	  const fetchAllData = async () => {
		setIsLoading(true); // Start loading before fetching data
	
		try {
		  await Promise.all([
			getCorporateData(),
			getCustomerData(),
			getCourseData(),
			getpurchasedCourseCount(),
			getCompletedCourseCount(),
			getContactUsFormCount(),
			getForumCount(),
			getTopSellingCourse(),
			getAverageCompletionRate(),
			getTopRatedCourse(),
			getAverageRatingCourse(),
		  ]);
		} catch (error) {
		  console.log("Error fetching data", error);
		} finally {
		  setIsLoading(false); // Stop loading after all data is fetched
		}
	  };
	
	  useEffect(() => {
		fetchAllData(); // Fetch data when component mounts
	  }, []);

	const handlePeriodChange = async (newPeriod) => {
		setPeriod(newPeriod); // Update the period state
		try {
			const result = await GetUserRegisteredStatistics(newPeriod); // Call the API
			setSalesCount(result.data.data || 0); // Update sales count with the result data
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		handlePeriodChange(period); // Fetch data for the default period when the component loads
	}, []);

	const handleGetTotalRevenueGenerated = async (newPeriod) => {
		setRevenuePeriod(newPeriod);
		try {
			const result = await GetTotalRevenueGenerated(newPeriod); // Call the API
			setTotalRevenueData(result.data.data || 0); // Update sales count with the result data
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		handleGetTotalRevenueGenerated(revenuePeriod); // Fetch data for the default period when the component loads
	}, []);

	const handleGetCoursesSoldStatistics = async (newPeriod) => {
		setCourseSoldPeriod(newPeriod);
		try {
			const result = await GetCoursesSoldStatistics(newPeriod); // Call the API
			setCourseSoldData(result.data.data || 0); // Update sales count with the result data
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		handleGetCoursesSoldStatistics(courseSoldPeriod); // Fetch data for the default period when the component loads
	}, []);

	const handleGetCorporateRegisteredStatistics = async (newPeriod) => {
		setCorporatePeriod(newPeriod);
		try {
			const result = await GetCorporateRegisteredStatistics(newPeriod); // Call the API
			setcorporateStatisticsData(result.data.data || 0); // Update sales count with the result data
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		handleGetCorporateRegisteredStatistics(corporatePeriod); // Fetch data for the default period when the component loads
	}, []);

	const config = {
		data: [{ type: period, sales: salesCount }],
		xField: "type",
		yField: "sales",
		color: "#4CAF50",
		columnWidthRatio: 0.2, // Adjust column width (0.6 is an example; adjust as needed)
		label: {
			position: "middle",
			style: {
				fill: "#FFFFFF",
				opacity: 1,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		yAxis: {
			min: 0, // Start the y-axis from 0
			max: 50, // End the y-axis at 50 or adjust based on your data
			tickInterval: 10, // Set interval to display ticks as 10, 20, 30, etc.
			label: {
				formatter: (v) => `${v}`, // Format y-axis labels as numbers
			},
		},
		meta: {
			type: {
				alias: "Period", // Label for the x-axis
			},
			sales: {
				alias: "Number of Registered Users", // Label for the y-axis
			},
		},
	};

	const corporateConfig = {
		data: [{ type: period, sales: corporateStatisticsData }],
		xField: "type",
		yField: "sales",
		color: "#1677FF",
		columnWidthRatio: 0.2, // Adjust column width (0.6 is an example; adjust as needed)
		label: {
			position: "middle",
			style: {
				fill: "#FFFFFF",
				opacity: 1,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		yAxis: {
			min: 0, // Start the y-axis from 0
			max: 50, // End the y-axis at 50 or adjust based on your data
			tickInterval: 10, // Set interval to display ticks as 10, 20, 30, etc.
			label: {
				formatter: (v) => `${v}`, // Format y-axis labels as numbers
			},
		},
		meta: {
			type: {
				alias: "Period", // Label for the x-axis
			},
			sales: {
				alias: "Number of Registered Users", // Label for the y-axis
			},
		},
	};

	const columns = [
		{
			title: "Course Image",
			key: "courseImage",
			width: "20%",
			render: (text, record) => {
				const fileAttachment =
					record.purchase_history_course?.course_attachments[0];
				if (fileAttachment) {
					const { file_name, file_uri } = fileAttachment;
					const imageUrl = `${BASE_URL_IMAGE}${file_name}`;
					return (
						<img
							src={imageUrl}
							alt="Course"
							style={{
								width: "50px",
								height: "50px",
								objectFit: "cover",
								borderRadius: "100px",
							}}
							crossOrigin="anonymous"
						/>
					);
				}
				return <span>No Image</span>;
			},
		},
		{
			title: "Course Name",
			dataIndex: ["purchase_history_course", "name"],
			key: "courseName",
			width: "20%",
		},
		{
			title: "Total Purchases",
			dataIndex: "total_purchases",
			key: "totalPurchases",
			width: "20%",
		},
	];

	const renderStars = (rating) => {
		const safeRating = Number(rating) || 0;
		return (
			<StarRatings
				rating={safeRating}
				starRatedColor="#FAAE42"
				starEmptyColor="gray"
				starDimension="20px"
				starSpacing="2px"
				numberOfStars={5}
				name="rating"
			/>
		);
	};

	const columnsTopRatedCourse = [
		{
			title: "Course Name",
			dataIndex: "name",
			key: "name",
			width: "20%",
		},
		{
			title: "Average Rating",
			dataIndex: "average_rating",
			key: "average_rating",
			width: "20%",
			render: (rating) => renderStars(rating),
		},
	];

	const columnsAverageRatedCourse = [
		{
			title: "Course Name",
			dataIndex: "name",
			key: "name",
			width: "20%",
		},
		{
			title: "Average Rating",
			dataIndex: "average_rating",
			key: "average_rating",
			width: "20%",
			render: (rating) => renderStars(rating),
		},
	];

	const pieConfig = {
		data: [
			{ type: "Completed", value: averageCompletionRateData.completionRate },
			{
				type: "Not Completed",
				value: 100 - averageCompletionRateData.completionRate,
			},
		],
		angleField: "value",
		colorField: "type",
		label: {
			type: "outer",
			content: "{name} {percentage}",
			style: {
				fontWeight: "bold",
			},
		},
		tooltip: {
			formatter: (datum) => ({
				name: datum.type,
				value: `${datum.value} users`,
			}),
		},
	};

	if (isLoading) {
		// Show the loading spinner
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh", // Full page height
					backgroundColor: "#f0f2f5",
				}}
			>
				<Spin size="large" />
			</div>
		);
	}

	return (
		<div>
			{/* header */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					backgroundColor: "white", // Changed to camelCase
					padding: "20px",
					borderRadius: "8px", // Changed to camelCase
					boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
					marginBottom: "20px", // Keep quotes for string values
				}}
			>
				<h3 className="dashboard_title">Welcome Admin 👋🏻</h3>
				<p className="page-sub-title" style={{ color: "green" }}>
					Here’s a snapshot of your latest statistics and insights to help you
					manage the platform effectively.
				</p>
			</div>
			{/* first section */}
			<div className="dashboard-container">
				{/* First Row with 2 small cards and 1 large card */}
				<div className="d-flex justify-content-between gap-3 mb-3">
					<div
						className="card-box small-card"
						style={{ borderRight: "5px solid #1677FF" }}
					>
						<div>
							<p className="desc">Total Users Registered</p>
							<h4 className="mb-0 sub-title">{customerData}</h4>
						</div>
						<Button
							label="	View Users"
							icon="pi pi-arrow-right"
							iconPos="right"
							style={{
								backgroundColor: "#EFF5FB",
								marginTop: "10px",
								border: "#EFF5FB",
								color: "black",
								borderRadius: "10px",
								fontSize: "13px",
								textAlign: "left",
								transition: "background-color 0.3s ease",
							}}
							onClick={() => navigate("/users")}
							onMouseOver={(e) => {
								e.target.style.backgroundColor = "#D7E8F8";
								e.target.style.color = "#black";
							}}
							onMouseOut={(e) => {
								e.target.style.backgroundColor = "#EFF5FB";
								e.target.style.color = "#black";
							}}
						></Button>
					</div>
					<div
						className="card-box small-card"
						style={{ borderRight: "5px solid #D82550" }}
					>
						<div>
							<p className="desc">Total Corporate Accounts</p>
							<h4 className="mb-0 sub-title">{corporateData}</h4>
						</div>
						<Button
							label="	View Corporate"
							icon="pi pi-arrow-right"
							iconPos="right"
							style={{
								backgroundColor: "#FFEFEE",
								marginTop: "10px",
								border: "#FFEFEE",
								color: "black",
								borderRadius: "10px",
								fontSize: "13px",
								textAlign: "left",
								transition: "background-color 0.3s ease",
							}}
							onClick={() => navigate("/corporate")}
							onMouseOver={(e) => {
								e.target.style.backgroundColor = "#F8D9D7";
								e.target.style.color = "#black";
							}}
							onMouseOut={(e) => {
								e.target.style.backgroundColor = "#FFEFEE";
								e.target.style.color = "#black";
							}}
						></Button>
					</div>
					<div
						className="card-box large-card"
						style={{ borderRight: "5px solid #FFD700" }}
					>
						<div>
							<p className="desc">Total Courses Available</p>
							<h4 className="mb-0 sub-title">{courseData}</h4>
							{/* Include a graph or placeholder for the chart */}
							<div className="graph-placeholder"></div>
						</div>
					</div>
				</div>

				{/* Second Row with 3 smaller cards */}
				<div className="d-flex justify-content-between gap-3 mb-3">
					<div
						className="card-box small-card"
						style={{ borderRight: "5px solid #1677FF" }}
					>
						<div>
							<p className="desc">Number of Users Who Purchased a Course</p>
							<h4 className="mb-0 sub-title">{purchasedCourseCount}</h4>
						</div>
					</div>
					<div
						className="card-box small-card"
						style={{ borderRight: "5px solid #1677FF" }}
					>
						<div>
							<p className="desc">Number of Forums Submitted</p>
							<h4 className="mb-0 sub-title">{forummData}</h4>
						</div>
					</div>
					<div
						className="card-box small-card"
						style={{ borderRight: "5px solid #1677FF" }}
					>
						<div>
							<p className="desc">Number of Contact Us Forms Received</p>
							<h4 className="mb-0 sub-title">{contactUsFormData}</h4>
						</div>
					</div>
				</div>

				{/* Card for Total Revenue Generated */}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flexDirection: "row",
						marginTop: "20px",
					}}
				>
					<div style={{ width: "50%", marginRight: "20px" }}>
						<Card
							style={{
								background: "linear-gradient(to right, #ff7e5f, #feb47b)",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
							}}
						>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<div>
										<h5 className="mb-5 title">Total Revenue Generated</h5>
									</div>
									<div style={{ marginBottom: "20px" }}>
										<Select
											defaultValue="weekly"
											style={{ width: 200 }}
											onChange={handleGetTotalRevenueGenerated}
										>
											<Option value="weekly">Weekly</Option>
											<Option value="monthly">Monthly</Option>
											<Option value="quarterly">Quarterly</Option>
											<Option value="annually">Annually</Option>
										</Select>
									</div>
								</div>
							</div>

							{/* Graph Section */}
							<div>
								<h5 style={{ color: "white" }}>${totalRevenueData}</h5>
							</div>
						</Card>
					</div>

					<div style={{ width: "50%" }}>
						<Card
							style={{
								background: "linear-gradient(to right, #8a5bc8, #4a98f0)",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
							}}
						>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<div>
										<h5 className="mb-5 title">Courses Sold</h5>
									</div>
									<div>
										<Select
											defaultValue="weekly"
											style={{ width: 200 }}
											onChange={handleGetCoursesSoldStatistics}
										>
											<Option value="weekly">Weekly</Option>
											<Option value="monthly">Monthly</Option>
											<Option value="quarterly">Quarterly</Option>
											<Option value="annually">Annually</Option>
										</Select>
									</div>
								</div>
							</div>

							{/* Graph Section */}
							<div>
								<h5 style={{ color: "white" }}>{courseSoldeData}</h5>
							</div>
						</Card>
					</div>
				</div>
			</div>

			{/* second row */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					marginTop: "20px",
				}}
			>
				<div style={{ width: "50%", marginRight: "20px" }}>
					<Card>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<div>
									<h5 className="mb-5 title">Users Registered</h5>
								</div>
								<div style={{ marginBottom: "20px" }}>
									<Select
										defaultValue="week"
										style={{ width: 100 }}
										onChange={handlePeriodChange}
									>
										<Option value="today">Today</Option>
										<Option value="week">This Week</Option>
										<Option value="month">This Month</Option>
									</Select>
								</div>
							</div>
						</div>

						{/* Graph Section */}
						<div>
							<Column {...config} />
						</div>
					</Card>
				</div>
				<div style={{ width: "50%" }}>
					<Card>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<div>
									<h5 className="mb-5 title">Corporates Registered</h5>
								</div>
								<div>
									<Select
										defaultValue="week"
										style={{ width: 100 }}
										onChange={handleGetCorporateRegisteredStatistics}
									>
										<Option value="today">Today</Option>
										<Option value="week">This Week</Option>
										<Option value="month">This Month</Option>
									</Select>
								</div>
							</div>
						</div>

						{/* Graph Section */}
						<div>
							<Column {...corporateConfig} />
						</div>
					</Card>
				</div>
			</div>

			{/* third course section */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					marginTop: "20px",
				}}
			>
				<div style={{ width: "50%", marginRight: "20px" }}>
					<Card
						style={{ height: "100%" }} // Ensure card takes full height of the container
					>
						<h4 className="headingTitle">Top Selling Courses</h4>
						<Table
							columns={columns}
							dataSource={topSellingCourseData}
							rowKey="course_id"
							pagination={false}
						/>
					</Card>
				</div>
				<div style={{ width: "50%" }}>
					<Card
						style={{
							height: "100%",
							marginBottom: "20px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<h4 className="headingTitle">Course Completion Rate</h4>
						<div
							style={{
								flex: 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Pie {...pieConfig} />
						</div>
					</Card>
				</div>
			</div>

			{/* fourth section */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					marginTop: "20px",
				}}
			>
				<div style={{ width: "50%", marginRight: "20px" }}>
					<Card>
						<h4 className="headingTitle">Top Highest rated course</h4>
						<Table
							columns={columnsTopRatedCourse}
							dataSource={topRatedCourse}
							rowKey="course_id"
							pagination={false}
						/>
					</Card>
				</div>
				<div style={{ width: "50%" }}>
					<Card>
						<h4 className="headingTitle">Average rating of courses</h4>
						<div className="scrollable-table-container">
							<Table
								columns={columnsAverageRatedCourse}
								dataSource={averageCouseRating}
								rowKey="course_id"
								pagination={false}
							/>
						</div>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
