/** @format */

import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
	CreateLecture,
	DeleteLecture,
	DeleteLectureQuestion,
	GetCourseById,
	GetQuestionsByLectureId,
	ManageLectureQuestions,
	saveUpdatedLectures,
	UpdateLecture,
	UpdateLectureQuestion,
} from "../../../services/Api/CourseApi";
import "./lecture.css"
import { BsThreeDots } from "react-icons/bs";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import ListGroup from "react-bootstrap/ListGroup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Menu, MenuItem, IconButton } from "@mui/material";
import { BASE_URL_IMAGE } from "../../../services/Host";
import { Col, Form, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { message, Modal, Drawer, Radio, Spin } from "antd";
import {
	CheckCircleFilled,
	CheckCircleOutlined,
	ExclamationCircleFilled,
} from "@ant-design/icons";
import ContentComponent from "../ContentComponent";
import DeleteAlert from "../../Test/DeleteAlert";
import Layout1 from "./Layout1";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { confirm } = Modal;

const CourseLectureEditor = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [courseData, setCourseData] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [lectureMenuAnchorEl, setLectureMenuAnchorEl] = useState(null);
	const [selectedLecture, setSelectedLecture] = useState(null);
	const [showSectionForm, setShowSectionForm] = useState(false);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const editorRef = useRef(null);
	const [disable, setDisable] = useState(false);
	const [isEditing, setIsEditing] = useState(false); // New state to track editing mode
	const [editLecture, setEditLecture] = useState(null);
	const [isQuizModalVisible, setIsQuizModalVisible] = useState(false);
	const [quizName, setQuizName] = useState("");
	const [isQuiz, setIsQuiz] = useState(false); // Track if the section is a quiz
	const [viewLecture, setViewLecture] = useState(null);
	const [questions, setQuestions] = useState([]);

	const fetchCourseData = async () => {
		setLoading(true); // Set loading state to true before making the API call
		try {
			const res = await GetCourseById(id);
			setCourseData(res.data.data);
			if (
				res.data.data.course_attachments &&
				res.data.data.course_attachments.length > 0
			) {
				const attachment = res.data.data.course_attachments[0];
				setImagePreview(`${BASE_URL_IMAGE}${attachment.file_name}`);
			}
		} catch (err) {
			console.log(err, "error");
		} finally {
			setLoading(false); // Set loading state to false after the API call is finished
		}
	};

	const handleLectureMenuClick = (event, lecture) => {
		setSelectedLecture(lecture);
		setLectureMenuAnchorEl(event.currentTarget);
	};

	const handleLectureMenuClose = () => {
		setLectureMenuAnchorEl(null);
		setSelectedLecture(null);
	};

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setDescription(data);
	};

	const customUploadAdapter = (loader) => {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						resolve({ default: reader.result });
					};
					reader.onerror = (error) => {
						reject(error);
					};
					loader.file.then((file) => {
						reader.readAsDataURL(file);
					});
				});
			},
		};
	};

	function CustomUploadAdapterPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return customUploadAdapter(loader);
		};
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!name) {
			message.error("Please enter name");
			setDisable(false);
			return;
		}

		if (!description) {
			message.error("Please enter description");
			setDisable(false);
			return;
		}

		try {
			console.log(id, "courseId");
			const formData = new FormData();
			formData.append("name", name);
			formData.append("content", description);
			formData.append("type", "Text");
			formData.append("course_id", id);

			const response = await CreateLecture(formData);

			if (response.status === 201) {
				message.success("Lecture added successfully!");
				setName("");
				setDescription("");
				setShowSectionForm(false);
				await fetchCourseData(); // Fetch course data again after adding a lecture
			}

			setDisable(false);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				message.error("Token expired");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	const handleClear = () => {
		setName("");
		// setDescription("");
		setIsEditing(false);
		setEditLecture(null);
		setShowSectionForm(false);

		// if (editorRef.current) {
		// 	editorRef.current.setData(""); // Clear CKEditor content
		// }
	};

	const handleChapterClick = () => {
		setShowSectionForm(true);
		setIsEditing(false);
		setEditLecture(null);
		setViewLecture(false);
	};

	const showDeleteConfirm = (lectureId) => {
		confirm({
			title: "Are you sure you want to delete this lecture?",
			icon: <ExclamationCircleFilled />,
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				handleDelete(lectureId);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleDelete = async (lectureId) => {
		try {
			await DeleteLecture(lectureId, localStorage.getItem("adminToken"));
			message.success("Lecture deleted successfully");
			await fetchCourseData();
		} catch (error) {
			message.error("Error deleting lecture");
		}
	};

	const handleLectureEditSubmit = async () => {
		setDisable(true);
		try {
			const formData = new FormData();
			formData.append("name", editLecture.name || "");
			formData.append("content", editLecture.content || "");

			const response = await UpdateLecture(editLecture.id, formData);

			if (response.status === 200) {
				message.success("Lecture updated successfully!");
				setIsEditing(false);
				setEditLecture(null);
				await fetchCourseData();
			}

			setDisable(false);
		} catch (error) {
			message.error("Failed to update lecture");
			setDisable(false);
		}
	};

	const handleEditorChangeEdit = (event, editor) => {
		const data = editor.getData();
		console.log(data, "data");
		setEditLecture((prevData) => ({
			...prevData,
			content: data,
		}));
		console.log(editLecture, "prevData");
	};

	const handleEditSection = () => {
		setIsEditing(true);
		setShowSectionForm(false);
		setIsQuizModalVisible(false);
		setViewLecture(false);
		setEditLecture(selectedLecture);
		setIsQuiz(selectedLecture?.type === "Quiz");
		handleLectureMenuClose();
	};

	const onChange = (e) => {
		setEditLecture({ ...editLecture, [e.target.name]: e.target.value });
	};

	const handleQuizClick = () => {
		setIsQuizModalVisible(true);
		setIsEditing(false);
		setEditLecture(false);
		setViewLecture(false);
		setShowSectionForm(false);
	};

	// Function to close the quiz modal
	const handleQuizModalClose = () => {
		setIsQuizModalVisible(false);
		setQuizName("");
	};

	const handlQuizeSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!quizName) {
			message.error("Please enter name");
			setDisable(false);
			return;
		}

		try {
			console.log(id, "courseId");
			const formData = new FormData();
			formData.append("name", quizName);
			formData.append("type", "Quiz");
			formData.append("course_id", id);

			const response = await CreateLecture(formData);

			if (response.status === 201) {
				message.success("Lecture added successfully!");
				setIsQuizModalVisible(false);
				setQuizName("");
				await fetchCourseData(); // Refresh course data
			}

			setDisable(false);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				message.error("Token expired");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	const handleQuizEditSubmit = async () => {
		setDisable(true);
		try {
			const formData = new FormData();
			formData.append("name", editLecture?.name || "");
			formData.append("type", "Quiz");

			const response = await UpdateLecture(editLecture.id, formData);

			if (response.status === 200) {
				message.success("Quiz updated successfully!");
				setIsEditing(false);
				setEditLecture(null);
				setIsQuiz(false); // Reset quiz state
				await fetchCourseData();
			}

			setDisable(false);
		} catch (error) {
			message.error("Failed to update quiz");
			setDisable(false);
		}
	};

	const handleItemClick = (lecture) => {
		console.log("I am clicked", lecture);
		setViewLecture(lecture);
		setSelectedLecture(lecture.id);
		setIsQuiz(null);
		// setIsQuizModalVisible(false);
		setIsQuizModalVisible(false);
		setIsEditing(true);
		setShowSectionForm(true);
	};

	const [question, setQuestion] = useState("");
	const [optionType, setOptionType] = useState(null);
	const [options, setOptions] = useState([]);
	const [showAddDrawer, setShowAddDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [editQuestionId, setEditQuestionId] = useState(null);

	useEffect(() => {
		if (viewLecture) {
			GetQuestionsByLectureId(viewLecture.id)
				.then((res) => {
					setQuestions(res.data.data.lecture_quiz);
				})
				.catch((error) => {
					console.log("error", error);
				});
		}
	}, [viewLecture]);

	useLayoutEffect(() => {
		fetchCourseData();
	}, [id]);

	const handleOptionTypeChange = (e) => {
		const selectedType = e.target.value;
		setOptionType(selectedType);
		setOptions(new Array(selectedType).fill("")); // Initialize options with empty strings
	};

	const handleOptionChange = (index, value) => {
		const updatedOptions = [...options];
		updatedOptions[index] = value;
		setOptions(updatedOptions);
	};

	const handleAnswerChange = (e) => {
		setSelectedAnswer(e.target.value);
	};

	const handleQuestionSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!question) {
			message.error("Please type Question");
			setDisable(false);
			return;
		}

		if (options.some((opt) => !opt)) {
			message.error("All options must be filled");
			setDisable(false);
			return;
		}

		// Check for duplicate options
		const uniqueOptions = new Set(options);
		if (uniqueOptions.size !== options.length) {
			message.error(
				"Duplicate options are not allowed within the same question."
			);
			setDisable(false);
			return;
		}

		if (!selectedAnswer) {
			message.error("Please select the correct answer");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("question", question);
			formData.append("lecture_id", viewLecture.id);
			formData.append("course_id", id);

			// Append each option individually
			options.forEach((option) => {
				formData.append("options[]", option);
			});

			formData.append("right_answer", selectedAnswer);

			let response;

			if (editQuestionId) {
				// Call Update API if in edit mode
				response = await UpdateLectureQuestion(editQuestionId, formData);
				if (response.status === 201) {
					message.success("Question updated successfully!");
					const questionsResponse = await GetQuestionsByLectureId(
						viewLecture.id
					);
					setQuestions(questionsResponse.data.data.lecture_quiz);
				}
			} else {
				// Call Add API if not in edit mode
				response = await ManageLectureQuestions(formData);
				if (response.status === 201) {
					message.success("Question added successfully!");
					const questionsResponse = await GetQuestionsByLectureId(
						viewLecture.id
					);
					setQuestions(questionsResponse.data.data.lecture_quiz);
				}
			}

			setQuestion(""); // Clear input field
			setOptions([]); // Reset options
			setOptionType(null); // Reset option type selection
			setSelectedAnswer(null); // Reset selected answer
			setShowAddDrawer(false); // Close add drawer
			setShowEditDrawer(false); // Close edit drawer
			setEditQuestionId(null); // Reset edit question ID
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					message.error("Token expired");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 1000);
				} else if (
					error.response.status === 400 &&
					error.response.data.message ===
						"This Question already exists for the quiz"
				) {
					message.error("This Question already exists for the quiz");
				} else if (
					error.response.status === 400 &&
					error.response.data.message ===
						"Duplicate options are not allowed within the same question."
				) {
					message.error(
						"Duplicate options are not allowed within the same question."
					);
				} else {
					message.error("Something went wrong");
				}
			} else {
				message.error("Network error");
			}
			setDisable(false);
		}
	};

	const handleDrawerClose = () => {
		setShowAddDrawer(false);
		setShowEditDrawer(false);
		setQuestion("");
		setOptionType(null);
		setOptions([]);
		setSelectedAnswer(null); // Reset selected answer
		setEditQuestionId(null); // Reset edit question ID
	};

	const handleDeleteQuestion = async (questionId) => {
		try {
			await DeleteLectureQuestion(questionId);
			message.success("Question deleted successfully");

			// Fetch the updated questions list
			const updatedQuestions = await GetQuestionsByLectureId(viewLecture.id);
			setQuestions(updatedQuestions.data.data.lecture_quiz);

			// Close the menu
			handleMenuClose();
		} catch (error) {
			message.error("Error deleting question");
		}
	};

	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [menuQuestionId, setMenuQuestionId] = useState(null);

	const handleMenuClick = (event, questionId, questionData) => {
		setMenuAnchorEl(event.currentTarget);
		setMenuQuestionId(questionId);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
		setMenuQuestionId(null);
	};

	const editQuestion = (questionData) => {
		setQuestion(questionData.question);
		setOptionType(questionData.quiz_answers.length); // Assuming 2 or 4 options
		setOptions(questionData.quiz_answers.map((opt) => opt.option));
		setSelectedAnswer(
			questionData.quiz_answers.find(
				(opt) => opt.id === questionData.right_answer_id
			)?.option || null
		);
		setEditQuestionId(questionData.id);
		setShowEditDrawer(true); // Open edit drawer
	};

	const handleDragEnd = (result) => {
		if (!result.destination) return;

		const reorderedLectures = Array.from(courseData.course_lectures);
		const [movedLecture] = reorderedLectures.splice(result.source.index, 1);
		reorderedLectures.splice(result.destination.index, 0, movedLecture);

		// Update lecture_number based on the new order
		const updatedLectures = reorderedLectures.map((lecture, index) => ({
			...lecture,
			lecture_number: index + 1,
		}));

		// Update state
		setCourseData({ ...courseData, course_lectures: updatedLectures });

		const courseId = courseData.id;
		saveUpdatedLectures(courseId, updatedLectures)
			.then((data) => {
				console.log(data.message); // Handle success message or UI update
			})
			.catch((error) => {
				console.error("Failed to update lecture order:", error);
			});
	};

	if (loading) {
		return (
			<div className="spinner-overlay">
				<Spin size="large" />
			</div>
		);
	}

	return (
		<Box>
			<Layout1 />
			{/* Second container */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
				}}
			>
				<div style={{ width: "30%", marginRight: "20px" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							marginBottom: "20px",
							gap: "10px",
						}}
					>
						<Button
							label="Chapter"
							icon="pi pi-plus"
							severity="info"
							onClick={handleChapterClick}
							style={{
								fontSize: "17px",
								width: "50%",
								padding: " 8px 20px",
							}}
						/>
						<Button
							label="Add Quiz"
							icon="pi pi-plus"
							severity="info"
							style={{
								fontSize: "17px",
								width: "50%",
								padding: "0 20px",
							}}
							onClick={handleQuizClick}
						/>
					</div>
					<Card style={{ width: "100%", marginRight: "30px", height: "380px" }}>
						<div style={{ height: "300px", overflowY: "auto" }}>
							{courseData && (
								<DragDropContext onDragEnd={handleDragEnd}>
									<Droppable droppableId="lectures">
										{(provided) => (
											<ListGroup
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{courseData.course_lectures.map((lecture, index) => (
													<Draggable
														key={lecture.id}
														draggableId={lecture.id.toString()}
														index={index}
													>
														{(provided) => (
															<ListGroup.Item
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={{
																	backgroundColor:
																		selectedLecture === lecture.id
																			? "#ECF4FF"
																			: "transparent",
																	...provided.draggableProps.style,
																}}
															>
																<div
																	style={{
																		display: "flex",
																		flexDirection: "row",
																		justifyContent: "space-between",
																		width: "100%",
																		cursor: "pointer",
																	}}
																>
																	<div
																		style={{
																			alignContent: "center",
																			color: "black",
																		}}
																		onClick={() => handleItemClick(lecture)}
																	>
																		{lecture.name}
																	</div>
																	<div>
																		<IconButton
																			aria-controls="lecture-menu"
																			aria-haspopup="true"
																			onClick={(event) =>
																				handleLectureMenuClick(event, lecture)
																			}
																		>
																			<BsThreeDots />
																		</IconButton>
																		<Menu
																			id="lecture-menu"
																			anchorEl={lectureMenuAnchorEl}
																			open={Boolean(lectureMenuAnchorEl)}
																			onClose={handleLectureMenuClose}
																		>
																			<MenuItem
																				onClick={() => {
																					handleEditSection();
																					handleLectureMenuClose();
																				}}
																			>
																				Edit Section
																			</MenuItem>
																			<MenuItem
																				onClick={() => {
																					showDeleteConfirm(
																						selectedLecture?.id
																					);
																					handleLectureMenuClose();
																				}}
																			>
																				Delete Section
																			</MenuItem>
																		</Menu>
																	</div>
																</div>
															</ListGroup.Item>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</ListGroup>
										)}
									</Droppable>
								</DragDropContext>
							)}
						</div>
					</Card>
				</div>
				<div style={{ width: "70%" }}>
					<Card style={{ height: "440px", overflowY: "scroll" }}>
						{showSectionForm && !isEditing && (
							<div>
								<Row>
									<Form.Group className="mb-3">
										<Form.Label>Section Name:</Form.Label>
										<Form.Control
											type="text"
											required
											placeholder="Enter Section Name"
											value={name}
											onChange={(e) => setName(e.target.value)}
											className="new_form_control"
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group className="mb-3">
										<Form.Label>Section Content:</Form.Label>
										<CKEditor
											editor={ClassicEditor}
											onChange={handleEditorChange}
											config={{
												height: "1000px",
												extraPlugins: [CustomUploadAdapterPlugin],
												toolbar: [
													"heading",
													"|",
													"bold",
													"italic",
													"link",
													"bulletedList",
													"numberedList",
													"blockQuote",
													"imageUpload",
													"undo",
													"redo",
												],
											}}
											onReady={(editor) => {
												editorRef.current = editor;
											}}
										/>
									</Form.Group>
								</Row>
								<div>
									<Button
										severity="info"
										onClick={handleSubmit}
										disabled={disable}
										style={{
											height: "45px",
											padding: "20px",
											borderRadius: "5px",
											margin: "10px",
										}}
									>
										{disable ? "Submitting..." : "Submit"}
									</Button>
									<Button
										severity="secondary"
										onClick={handleClear}
										style={{
											height: "45px",
											padding: "20px",
											borderRadius: "5px",
											margin: "10px",
										}}
									>
										Clear
									</Button>
								</div>
							</div>
						)}

						{isEditing && isQuiz && (
							<Modal
								// className="heymodal"
								title="Edit Quiz Name"
								visible={isEditing && isQuiz}
								onCancel={handleClear}
								footer={[
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginLeft: "52%",
										}}
									>
										<Button
											key="cancel"
											severity="secondary"
											onClick={handleClear}
										>
											Cancel
										</Button>
										<Button
											key="submit"
											severity="info"
											onClick={handleQuizEditSubmit}
										>
											Save Changes
										</Button>
									</div>,
								]}
							>
								<Row>
									<Form.Group className="mb-3">
										<Form.Label>Quiz Name:</Form.Label>
										<Form.Control
											name="name"
											type="text"
											value={editLecture?.name || ""}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>
							</Modal>
						)}

						{isEditing && !isQuiz && !showSectionForm && (
							<div>
								<Row>
									<Form.Group className="mb-3">
										<Form.Label>Section Name:</Form.Label>
										<Form.Control
											name="name"
											type="text"
											value={editLecture?.name || ""}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group className="mb-3">
										<Form.Label>Section Content:</Form.Label>
										<CKEditor
											data={editLecture?.content || ""}
											editor={ClassicEditor}
											onChange={handleEditorChangeEdit}
											config={{
												height: "1000px",
												extraPlugins: [CustomUploadAdapterPlugin],
												toolbar: [
													"heading",
													"|",
													"bold",
													"italic",
													"link",
													"bulletedList",
													"numberedList",
													"blockQuote",
													"imageUpload",
													"undo",
													"redo",
												],
											}}
											onReady={(editor) => {
												editorRef.current = editor;
												editor.setData(editLecture?.content || "");
											}}
										/>
									</Form.Group>
								</Row>
								<div>
									<Button
										severity="info"
										onClick={handleLectureEditSubmit}
										disabled={disable}
										style={{
											height: "45px",
											padding: "20px",
											borderRadius: "5px",
											margin: "10px",
										}}
									>
										{disable ? "Updating..." : "Update"}
									</Button>
									<Button
										severity="secondary"
										onClick={handleClear}
										style={{
											height: "45px",
											padding: "20px",
											borderRadius: "5px",
											margin: "10px",
										}}
									>
										Cancel
									</Button>
								</div>
							</div>
						)}

						{!showSectionForm && !isEditing && (
							<div
								style={{
									fontWeight: "bold",
									textAlign: "center",
									padding: "180px",
								}}
							>
								NO SECTION SELECTED YET
							</div>
						)}

						{viewLecture?.type === "Text" && (
							<div style={{ overflowX: "scroll" }}>
								<h4>{viewLecture?.name}</h4>

								<ContentComponent htmlContent={viewLecture?.content} />
							</div>
						)}

						{viewLecture?.type === "Quiz" && (
							<div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										marginBottom: "20px",
									}}
								>
									<h2>{viewLecture?.name}</h2>
									<Button
										label="Add New Question"
										icon="pi pi-plus"
										style={{ height: "44px" }}
										onClick={() => setShowAddDrawer(true)} // Open add drawer
									/>
								</div>

								{Array.isArray(questions) && questions.length > 0 ? (
									<Form style={{ padding: "10px" }}>
										{questions.map((q, qIndex) => (
											<Form.Group
												as={Row}
												className="mb-3"
												key={q.id}
												style={{ width: "90%" }}
											>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "space-between",
														width: "100%",
													}}
												>
													<Form.Label as="legend" column sm={12}>
														<strong>
															{qIndex + 1}. {q.question}
														</strong>
													</Form.Label>
													<IconButton
														aria-controls={`question-menu-${q.id}`}
														aria-haspopup="true"
														onClick={(event) => handleMenuClick(event, q.id, q)}
														style={{
															marginLeft: "10px",
															cursor: "pointer",
															width: "10%",
														}}
													>
														<MoreVertIcon />
													</IconButton>
													<Menu
														id={`question-menu-${q.id}`}
														anchorEl={menuAnchorEl}
														open={
															Boolean(menuAnchorEl) && menuQuestionId === q.id
														}
														onClose={handleMenuClose}
														anchorOrigin={{
															vertical: "bottom",
															horizontal: "right",
														}}
														transformOrigin={{
															vertical: "top",
															horizontal: "right",
														}}
													>
														<MenuItem onClick={() => editQuestion(q)}>
															Edit Question
														</MenuItem>
														<MenuItem>
															<DeleteAlert
																title="Question"
																handleDelete={() => handleDeleteQuestion(q.id)}
															/>
														</MenuItem>
													</Menu>
												</div>
												<Col sm={24}>
													{q.quiz_answers.length > 0 ? (
														<ul
															style={{ listStyleType: "none", paddingLeft: 0 }}
														>
															{q.quiz_answers.map((answer, aIndex) => (
																<li
																	key={answer.id}
																	style={{
																		display: "flex",
																		alignItems: "center",
																		marginBottom: "8px",
																	}}
																>
																	{aIndex + 1}. {answer.option}
																	{answer.id === q.right_answer_id && (
																		<CheckCircleFilled
																			twoToneColor="#52c41a"
																			style={{
																				color: "darkgreen",
																				marginLeft: "10px",
																				fontSize: "18px",
																			}}
																		/>
																	)}
																</li>
															))}
														</ul>
													) : (
														<p style={{ color: "red" }}>No options added yet</p>
													)}
												</Col>
											</Form.Group>
										))}
									</Form>
								) : (
									<p style={{ color: "red" }}>No questions added yet</p>
								)}
							</div>
						)}
					</Card>
				</div>
			</div>

			<div style={{ display: "flex", flexDirection: "row-reverse" }}>
				<Button
					icon="pi pi-check"
					onClick={() => navigate(`/course`)}
					// lable="Complete"
					style={{
						height: "45px",
						marginLeft: "10px",
						padding: "20px",
						borderRadius: "5px",
						marginTop: "20px",
						backgroundColor: "green",
						border: "none",
					}}
				></Button>
			</div>

			<Modal
				title="Add New Quiz"
				visible={isQuizModalVisible}
				onCancel={handleQuizModalClose}
				footer={[
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginLeft: "52%",
						}}
					>
						<Button
							key="cancel"
							severity="secondary"
							onClick={handleQuizModalClose}
						>
							Cancel
						</Button>
						<Button key="submit" severity="info" onClick={handlQuizeSubmit}>
							Save Changes
						</Button>
					</div>,
				]}
			>
				<Row>
					<Form.Group className="mb-3">
						<Form.Label>Quiz Name:</Form.Label>
						<Form.Control
							type="text"
							value={quizName}
							onChange={(e) => setQuizName(e.target.value)}
						/>
					</Form.Group>
				</Row>
			</Modal>

			<Drawer
				title="Add New Question"
				placement="right"
				closable={true}
				onClose={handleDrawerClose}
				open={showAddDrawer}
				width={400}
			>
				<Form>
					<Form.Group className="mb-3 w-100">
						<Form.Label>Question:</Form.Label>
						<Form.Control
							type="text"
							required
							placeholder="Please enter Question here"
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
							className="new_form_control"
							id="question-input"
						/>
					</Form.Group>

					<Form.Group className="mb-3 w-100">
						<Form.Label>Choose Option Type:</Form.Label>
						<Radio.Group onChange={handleOptionTypeChange} value={optionType}>
							<Radio value={2}>2-Option Type</Radio>
							<Radio value={4}>4-Option Type</Radio>
						</Radio.Group>
					</Form.Group>

					{optionType &&
						options.map((option, index) => (
							<Form.Group key={index} className="mb-3">
								<Form.Label>Option {index + 1}:</Form.Label>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										width: "100%",
										gap: "10px",
									}}
								>
									<Form.Control
										type="text"
										placeholder={`Enter option ${index + 1}`}
										value={option}
										onChange={(e) => handleOptionChange(index, e.target.value)}
										className="new_form_control"
									/>
									<Radio
										name="answer" // Ensure all radio buttons in this group have the same name
										value={option}
										checked={selectedAnswer === option}
										onChange={handleAnswerChange}
										style={{
											padding: ".375rem .75rem",
											border: "1px solid #ced4da",
											borderRadius: "5px",
										}}
									/>
								</div>
							</Form.Group>
						))}
				</Form>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						width: "100%",
						marginTop: "40px",
					}}
				>
					<Button
						label="Cancel"
						severity="secondary"
						onClick={handleDrawerClose}
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "8px 20px",
						}}
					/>
					<Button
						label="Save"
						// loading={disable}
						type="submit"
						onClick={handleQuestionSubmit}
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "5px 20px",
						}}
					/>
				</div>
			</Drawer>

			<Drawer
				title="Edit Question"
				placement="right"
				closable={true}
				onClose={handleDrawerClose}
				open={showEditDrawer}
				width={500}
			>
				<Form>
					<Form.Group controlId="question" className="mb-3 w-100">
						<Form.Label>Question</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
						/>
					</Form.Group>

					{options.map((option, index) => (
						<Form.Group
							controlId={`option${index}`}
							key={index}
							className="mb-3 w-100"
						>
							<Form.Label>Option {index + 1}</Form.Label>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
									gap: "10px",
								}}
							>
								<Form.Control
									type="text"
									value={option}
									onChange={(e) => handleOptionChange(index, e.target.value)}
									style={{ marginRight: "10px" }}
								/>
								<Radio
									value={option}
									checked={selectedAnswer === option}
									onChange={handleAnswerChange}
									style={{
										padding: ".375rem .75rem",
										border: "1px solid #ced4da",
										borderRadius: "5px",
									}}
								/>
							</div>
						</Form.Group>
					))}
				</Form>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						width: "100%",
						marginTop: "40px",
					}}
				>
					<Button
						label="Cancel"
						severity="secondary"
						onClick={handleDrawerClose}
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "8px 20px",
						}}
					/>
					<Button
						type="submit"
						onClick={handleQuestionSubmit}
						// disabled={disable}
						label="Update"
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "8px 20px",
						}}
					/>
				</div>
			</Drawer>
		</Box>
	);
};

export default CourseLectureEditor;
